<template>
  <div class="mx-auto">
    <TitleWrapper
      :filter-option="false"
      title="SYLLABUS"
      tooltip-title="SYLLABUS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="minHieght80 flex-col">
      <div v-if="!loading">
        <SyllabusCheckbox
          v-for="(val, ind) in settingsArray"
          :key="ind"
          parent-module="syllabus"
          :title="ind"
          class="mt-6"
          :modules-array="val"
          @updateSettings="getSettings"
        />
      </div>
      <Loader v-else class="mt-28" :content="true" />
    </div>
  </div>
</template>

<script>
import TitleWrapper from '@components/TitleWrapper.vue'
import SyllabusCheckbox from '@src/components/Settings/attandance-settings/PermissionSettingsCheckbox.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { removeExtraRolePermissions } from '@utils/permissions'

/* 3rd party Component */
import { mapActions, mapState } from 'vuex'
/**
 * @notes IMPORTANT NOTES
 * index ACTION FOR VIEW
 * create ACTION FOR Create
 * update ACTION FOR Edit
 * deactivate ACTION FOR delete
 * */

export default {
  components: {
    TitleWrapper,
    SyllabusCheckbox,
    Loader,
  },
  data: function () {
    return {
      settingsArray: [],
      loading: false,
      excludeRoleSettings: ['campus_moderator', 'section_moderator', 'section_admin'],
    }
  },
  page: {
    title: 'System Settings | Syllabus Settings',
    meta: [
      {
        name: 'description',
        content: 'Syllabus Settings',
      },
    ],
  },
  computed: {
    ...mapState('layout', ['currentCampusScope', 'currentSectionScope', 'currentClassScope']),
  },
  watch: {
    currentCampusScope: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentSectionScope: {
      handler: function () {
        this.getSettings()
      },
      deep: true,
    },
    currentClassScope: {
      handler() {
        this.getSettings()
      },
    },
  },

  async mounted() {
    this.getSettings()
  },

  methods: {
    async getSettings() {
      this.loading = true
      const [res, err] = await this.getSyllabusPermissionSettings()
      removeExtraRolePermissions(this.excludeRoleSettings, res.data)
      this.settingsArray = res.data
      this.loading = false
    },
    ...mapActions('settings', ['getSyllabusPermissionSettings']),
  },
}
</script>
